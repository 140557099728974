import * as R from 'ramda'
import {arrayOf, shape, string} from 'prop-types'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React from 'react'

import {pageContextPropTypes} from 'helpers/propTypes'
import ContentSection from 'components/WhitePaper/ContentSection'
import GradientWave from 'components/WhitePaper/GradientWave'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import useIsMobile from 'hooks/useIsMobile'
import WhitePaperHero from 'components/UI/Hero/HeroComponent/WhitePaperHero'

const WhitePaper = ({pageContext, data}) => {
  const Related = loadable(() => import('components/UI/Related'))
  const isMobile = useIsMobile()
  const {hasShortPath} = pageContext
  const countryData = R.path(['contentfulCountry'], data)
  const {countryCode, navLocale} = R.path(['contentfulCountry'], data)

  const relatedPrefix = hasShortPath
    ? `../${countryCode}/${navLocale}/insights/`
    : '../'

  const relatedInsight = R.pathOr(null, ['relatedInsight', 'edges'], data)

  const {insightOtherArticlesLabel} = R.pathOr(
    null,
    ['allContentfulInsights', 'nodes', 0],
    data,
  )

  const {title: hat} = R.pathOr(
    null,
    ['allContentfulWhitePapers', 'edges', 0, 'node'],
    data,
  )

  const {
    metaTitle,
    metaDescription,
    title,
    description,
    slug,
    color,
    content,
    image,
    images,
    form,
  } = R.pathOr(null, ['allContentfulWhitePaper', 'nodes', 0], data)

  const gradientStops =
    color === 'Orange' ? (
      <>
        <stop offset="0%" stopColor="rgba(252, 153, 14, 0)" />
        <stop offset="100%" stopColor="#fc990e29" />
      </>
    ) : (
      <>
        <stop offset="0%" stopColor="rgba(39, 201, 255, 0)" />
        <stop offset="100%" stopColor="#27c9ff29" />
      </>
    )

  const pageMetaData = {
    article: true,
    image: image && R.pathOr(' ', ['gatsbyImageData'], image),
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section
        styles={{
          marginBottom: '-07vh',
        }}
        hasPaddingTop={false}
        hasPaddingBottom={false}
      >
        <GradientWave stops={gradientStops} isMobile={isMobile} />
        <WhitePaperHero
          hat={hat}
          title={title}
          titleType="bigTitle"
          description={description}
          descriptionType="description"
          media={image}
          pagesMedia={images}
          hasAutoWidth
          isWhitePaper
          flexLeft={5}
          flexRight={4}
        />
      </Section>
      <Section
        styles={{
          background: 'transparent',
        }}
        hasPaddingTop={false}
        hasPaddingBottom={false}
      >
        <ContentSection content={content} form={form} />
      </Section>
      {relatedInsight && (
        <Related
          cards={relatedInsight}
          title={insightOtherArticlesLabel}
          cardSize="wide"
          buttonsPosition="bottom"
          prefix={relatedPrefix}
        />
      )}
    </>
  )
}

WhitePaper.propTypes = {
  data: shape({
    contentfulCountry: shape({
      countryCode: string.isRequired,
      navLocale: string.isRequired,
    }).isRequired,
    allContentfulWhitePapers: shape({
      edges: arrayOf(
        shape({
          node: shape({
            title: string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    allContentfulWhitePaper: shape({
      nodes: arrayOf(
        shape({
          title: string.isRequired,
          description: string.isRequired,
          slug: string.isRequired,
          color: string.isRequired,
          content: shape({}).isRequired,
          image: shape({
            file: shape({
              url: string.isRequired,
              contentType: string,
            }).isRequired,
          }).isRequired,
          images: arrayOf(
            shape({
              file: shape({
                url: string.isRequired,
                contentType: string,
              }).isRequired,
            }),
          ),
          form: shape({}),
        }),
      ).isRequired,
    }).isRequired,
    allContentfulInsights: shape({
      nodes: arrayOf(
        shape({
          insightOtherArticlesLabel: string,
        }),
      ).isRequired,
    }).isRequired,
    relatedInsight: shape({
      edges: arrayOf(shape({})),
    }),
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export default WhitePaper

export const pageQuery = graphql`
  query templateWhitePaper(
    $nodeLocale: String
    $contentId: String
    $technicalName: String
  ) {
    allContentfulWhitePapers(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          title
        }
      }
    }
    allContentfulWhitePaper(
      filter: {node_locale: {eq: $nodeLocale}, contentful_id: {eq: $contentId}}
    ) {
      nodes {
        metaTitle {
          metaTitle
        }
        metaDescription {
          metaDescription
        }
        title
        description
        image {
          file {
            url
            contentType
          }
          # gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        images {
          file {
            url
            contentType
          }
          # gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        slug
        color
        form
        content {
          __typename
          raw
          references {
            ... on ContentfulAsset {
              description
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              contentful_id
              file {
                url
                contentType
              }
              __typename
            }
            ... on ContentfulEntry {
              contentful_id
              __typename
              ... on ContentfulCallToAction {
                contentful_id
                __typename
                callToActionText
                callToActionLink
              }
            }
          }
        }
      }
    }
    allContentfulInsight(
      filter: {node_locale: {eq: $nodeLocale}, contentful_id: {eq: $contentId}}
    ) {
      nodes {
        ctaPosition
        author
        slug
        title
        node_locale
        contentful_id
        publicationDate(formatString: "DD.MM.YY")
        image {
          file {
            url
            contentType
          }
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        metaTitle {
          metaTitle
        }
        metaDescription {
          metaDescription
        }
        type {
          name
        }
        content {
          __typename
          raw
          references {
            ... on ContentfulAsset {
              description
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              contentful_id
              file {
                url
                contentType
              }
              __typename
            }
            ... on ContentfulEntry {
              contentful_id
              __typename
              ... on ContentfulCallToAction {
                contentful_id
                __typename
                callToActionText
                callToActionLink
              }
            }
          }
        }
        industries {
          name {
            name
          }
        }
        calToAction
        callToActionIsForm
        callToActionFormId
        formPopupTitle
        externalLink
        services2 {
          name
        }
      }
    }
    relatedInsight: allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
        contentful_id: {ne: $contentId}
      }
      limit: 10
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          ...insightFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            description
          }
        }
      }
    }
    allContentfulInsights(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        insightBackButtonLabel
        insightOtherArticlesLabel
        insightShareButtons {
          icon {
            file {
              url
            }
          }
        }
        node_locale
        newsletterCallToAction
        newsletterDescription
        newsletterPlaceholder
        newsletterTitle
        newsletterImage {
          file {
            url
            fileName
          }
        }
        newsletterGdprConsent {
          raw
          references {
            ... on ContentfulSimplePage {
              contentful_id
              slug
              name
            }
          }
        }
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      id
      name
      newsletterCampaignToken
      salesforce
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    contentfulForm(name: {eq: "Lead form"}, node_locale: {eq: $nodeLocale}) {
      ...FormFields
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
  }
`
