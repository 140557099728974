import Box from '@mui/material/Box'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import React from 'react'

import useStyles from './styles'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '800px',
  backgroundColor: 'background.paper',
  boxShadow: 24,
  p: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const imageStyle = {
  width: '100%',
  maxHeight: '95vh',
  objectFit: 'contain',
  position: 'relative',
}

const LEFT_ARROW_CURSOR =
  'url("data:image/svg+xml,%3Csvg%20width%3D%2732%27%20height%3D%2732%27%20viewBox%3D%270%200%2032%2032%27%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%3E%3Ccircle%20cx%3D%2716%27%20cy%3D%2716%27%20r%3D%2714%27%20fill%3D%27%23E0E0E0%27%20stroke%3D%27black%27%20stroke-width%3D%272%27/%3E%3Cpath%20fill%3D%27black%27%20d%3D%27M18%2010%20L12%2016%20L18%2022%20Z%27/%3E%3C/svg%3E") 16 16, auto'

const RIGHT_ARROW_CURSOR =
  'url("data:image/svg+xml,%3Csvg%20width%3D%2732%27%20height%3D%2732%27%20viewBox%3D%270%200%2032%2032%27%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%3E%3Ccircle%20cx%3D%2716%27%20cy%3D%2716%27%20r%3D%2714%27%20fill%3D%27%23E0E0E0%27%20stroke%3D%27black%27%20stroke-width%3D%272%27/%3E%3Cpath%20fill%3D%27black%27%20d%3D%27M14%2010%20L20%2016%20L14%2022%20Z%27/%3E%3C/svg%3E") 16 16, auto'

export default function BasicModal({
  media = [],
  open,
  onClose,
  currentIndex,
  setCurrentIndex,
}) {
  const classes = useStyles()

  const handleNext = () => {
    setCurrentIndex(prev => Math.min(prev + 1, media.length - 1))
  }

  const handlePrevious = () => {
    setCurrentIndex(prev => Math.max(prev - 1, 0))
  }

  const currentMedia = media[currentIndex]

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          onClick={onClose}
          aria-label="Close modal"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'black',
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {currentMedia && (
            <Box
              component="img"
              src={`https:${currentMedia.file.url}`}
              alt={currentMedia.title || 'Image'}
              sx={imageStyle}
            />
          )}

          <Box
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: '50%',
              cursor: currentIndex === 0 ? 'default' : LEFT_ARROW_CURSOR,
            }}
          />

          <Box
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: '50%',
              cursor:
                currentIndex === media.length - 1
                  ? 'default'
                  : RIGHT_ARROW_CURSOR,
            }}
          />

          {currentIndex > 0 && (
            <ChevronLeftIcon
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              aria-label="Previous image"
              sx={{
                position: 'absolute',
                top: '50%',
                left: 16,
                transform: 'translateY(-50%)',
                zIndex: 3,
                cursor: currentIndex === 0 ? 'default' : LEFT_ARROW_CURSOR,
              }}
              fontSize="large"
            />
          )}

          {currentIndex < media.length - 1 && (
            <ChevronRightIcon
              onClick={handleNext}
              disabled={currentIndex === media.length - 1}
              aria-label="Next image"
              sx={{
                position: 'absolute',
                top: '50%',
                right: 16,
                transform: 'translateY(-50%)',
                zIndex: 3,
                cursor:
                  currentIndex === media.length - 1
                    ? 'default'
                    : RIGHT_ARROW_CURSOR,
              }}
              fontSize="large"
            />
          )}
        </Box>
      </Box>
    </Modal>
  )
}
