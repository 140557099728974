import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints}) => ({
  container: {
    width: 500,
    position: 'relative',
    margin: 'auto',
    [breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  formSubmitted: {
    '& .hs-form-iframe': {
      height: '100% !important',
    },
  },
}))

export default useStyles
