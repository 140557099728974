import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing}) => ({
  mobileContainer: {
    margin: '0 auto',
  },
  formContainer: {
    display: 'block',
    maxWidth: '500px',
    width: '100%',
    padding: spacing(4),
    margin: spacing(4, 0),
    background: palette.background.paper,
    borderRadius: spacing(2),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  contentContainer: {
    display: 'block',
    position: 'relative',
    paddingRight: spacing(4),
  },
  verticalDivider: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  desktopContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: spacing(8),
    margin: '0 auto',
  },
  textContainer: {
    flex: '1 1 0',
    position: 'relative',
    paddingRight: spacing(4),
  },
  stickyFormContainer: {
    flex: '0 0 auto',
    maxWidth: '450px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(4),
    margin: spacing(4, 0),
    background: palette.background.paper,
    borderRadius: spacing(2),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'sticky',
    top: spacing(14),
  },
}))

export default useStyles
