import React from 'react'

import HubspotWhitePaperEmbedForm from 'components/WhitePaper/HubspotWhitePaperEmbedForm'
import RawText from 'components/UI/RawText'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const ContentSection = ({content, form}) => {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return isMobile ? (
    <div className={classes.mobileContainer}>
      <div className={classes.formContainer}>
        <HubspotWhitePaperEmbedForm formId={form} />
      </div>

      <div className={classes.contentContainer}>
        <RawText hasPaddingBottom={false} text={content} />
        <div className={classes.verticalDivider} />
      </div>
    </div>
  ) : (
    <div className={classes.desktopContainer}>
      <div className={classes.textContainer}>
        <RawText hasPaddingBottom={false} text={content} />
        <div className={classes.verticalDivider} />
      </div>

      <div className={classes.stickyFormContainer}>
        <HubspotWhitePaperEmbedForm formId={form} />
      </div>
    </div>
  )
}

export default ContentSection
