import React from 'react'

const GradientWave = ({stops, isMobile}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 700"
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: isMobile ? '700px' : 'auto',
      zIndex: 0,
    }}
  >
    <defs>
      <linearGradient id="waveGradient" x1="0" y1="0" x2="0" y2="1">
        {stops}
      </linearGradient>
    </defs>

    <path
      fill="url(#waveGradient)"
      d="M0,600L60,580C120,560,240,530,360,500C480,470,600,440,720,430C840,420,960,440,1080,480C1200,520,1320,580,1380,610L1440,630L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
    />
  </svg>
)

export default GradientWave
