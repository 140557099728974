import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({layout, breakpoints, spacing, palette}) => ({
  mainImageWrapper: {
    position: 'relative',
    width: '100%',
    height: '500px',
    maxWidth: '800px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  slideWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: spacing(12.5, 0),
    [breakpoints.up('sm')]: {
      gap: spacing(16),
    },
    [breakpoints.down('sm')]: {
      height: 'auto',
      flexDirection: 'column-reverse',
      margin: spacing(6.9, 'auto'),
      maxWidth: layout.mainCarousel.slideWrapper.maxWidth,
    },
  },
  slideLeft: {
    width: '50%',
    paddingRight: '11%',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  autoWidth: {
    width: 'auto',
    paddingRight: 'inherit',
  },
  slideRight: {
    width: '50%',
    maxWidth: layout.mainCarousel.slideRight.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    [breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      margin: spacing(0, 'auto', 2, 'auto'),
      display: 'inherit',
      flexDirection: 'inherit',
      justifyContent: 'inherit',
    },
  },
  slideDescriptionContainer: {
    [breakpoints.down('sm')]: {
      order: 2,
    },
  },
  slideImageContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'zoom-in',
    '& > img, & > video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [breakpoints.down('sm')]: {
      height: 'auto',
      '& > img, & > video': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
      },
    },
  },
  mediaPlaceholder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#aaa',
    fontSize: '1rem',
    textAlign: 'center',
  },
  hasRoundCorner: {
    borderRadius: layout.mainCarousel.image.borderRadius,
    [breakpoints.down('sm')]: {
      borderRadius: layout.mainCarousel.image.borderRadiusMobile,
    },
  },
  hasSmallVerticalMargin: {
    margin: spacing(6, 0),
    [breakpoints.down('sm')]: {
      margin: spacing(3, 5, 'auto'),
    },
  },
  offsetBottom: {
    marginBottom: spacing(22.25),
  },
  isAlignCenter: {
    alignItems: 'center',
  },
  slideRightCta: {
    textAlign: 'center',
  },
  whitePaperMedia: {
    '& > img': {
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px 16px 16px 4px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      margin: 'auto',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '30px',
      width: '1px',
      height: '100%',
      backgroundColor: '#ffffffb8',
      opacity: 0.6,
      [breakpoints.down('md')]: {
        left: '70px',
      },
    },
    padding: '10px 16px',
    position: 'relative',
    [breakpoints.down('sm')]: {
      border: '1px dashed white',
      padding: spacing(1.5),
    },
  },
  mainAndThumbs: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  thumbnailContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    padding: '10px 0',
    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  thumbnail: {
    width: 80,
    height: 80,
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: 4,
    transition: '0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  thumbnailActive: {
    outline: `2px solid ${palette.primary.main}`,
    outlineOffset: 2,
  },
}))

export default useStyles
