/* eslint-disable no-undef */
import {string} from 'prop-types'
import classnames from 'classnames'
import React, {useEffect, useState} from 'react'

import {addExternalScript} from 'helpers/utils'
import useStyles from './styles'

const HubspotWhitePaperEmbedForm = ({formId}) => {
  const classes = useStyles()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const initEmbeddedForm = async () => {
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        region: 'eu1',
        portalId: '25509106',
        formId,
        target: '#hubspotPopupForm',
        onFormSubmit() {
          setIsFormSubmitted(true)
        },
      })
    }
  }

  useEffect(() => {
    addExternalScript('https://js-eu1.hsforms.net/forms/v2.js', () =>
      initEmbeddedForm(),
    )
  }, [formId])

  return (
    <div
      className={classnames(classes.container, {
        [classes.formSubmitted]: isFormSubmitted,
      })}
    >
      <div id="hubspotPopupForm" />
    </div>
  )
}

HubspotWhitePaperEmbedForm.propTypes = {
  formId: string,
}

HubspotWhitePaperEmbedForm.defaultProps = {
  formId: '',
}

export default HubspotWhitePaperEmbedForm
