import * as R from 'ramda'
import {animated, useSpring} from 'react-spring'
import {number, shape} from 'prop-types'
import classNames from 'classnames'
import React, {useEffect, useState} from 'react'

import {heroPropTypes} from 'helpers/propTypes'

import Description from 'components/UI/Description'
import Heading from 'components/UI/Heading'
import ImageModal from 'components/UI/ImageModal'
import Media from 'components/UI/Media'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const Hero = ({
  ctaColor,
  ctaLink,
  ctaTitle,
  description,
  descriptionType,
  hasCtaOnRight,
  hasRoundCorner,
  hasSmallVerticalMargin,
  hat,
  isAlignCenter,
  media,
  pagesMedia,
  offsetBottom,
  title,
  titleType,
  hasAutoWidth,
  isWhitePaper,
  flexLeft,
  flexRight,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    if (!isMobile) {
      setIsModalOpen(true)
    }
  }

  const handleCloseModal = () => setIsModalOpen(false)

  const [slideStyles, api] = useSpring(() => ({
    opacity: 1,
    transform: 'translateX(0%)',
    config: {tension: 120, friction: 14},
  }))

  useEffect(() => {
    api.start({
      from: {opacity: 0, transform: 'translateX(100%)'},
      to: {opacity: 1, transform: 'translateX(0%)'},
    })
  }, [currentIndex, api])

  const allMedias = media ? [media, ...(pagesMedia || [])] : []

  const thumbnailImages = allMedias

  const renderMediaBlock = () => {
    if (!media) return null

    const currentMedia = allMedias[currentIndex]

    return (
      <div className={classes.mainAndThumbs}>
        <div
          className={classNames(classes.mainImageWrapper, {
            [classes.hasRoundCorner]: hasRoundCorner,
          })}
          onClick={!isMobile ? handleOpenModal : undefined}
        >
          <animated.div
            key={currentIndex}
            style={slideStyles}
            className={classNames(classes.slideImageContainer, {
              [classes.hasRoundCorner]: hasRoundCorner,
              [classes.whitePaperMedia]: isWhitePaper,
            })}
          >
            {currentMedia ? (
              <Media data={currentMedia} />
            ) : (
              <div className={classes.mediaPlaceholder}>Loading...</div>
            )}
          </animated.div>
        </div>

        {R.gt(R.length(thumbnailImages), 1) && (
          <div className={classes.thumbnailContainer}>
            {thumbnailImages.map((item, index) => {
              if (!item) return null

              const isActive = currentIndex === index

              return (
                <img
                  key={index}
                  src={`https:${item.file.url}?w=100&h=100&fit=thumb`}
                  alt={item.title || 'Thumbnail'}
                  className={classNames(classes.thumbnail, {
                    [classes.thumbnailActive]: isActive,
                  })}
                  onClick={() => {
                    setCurrentIndex(index)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        classes.slideWrapper,
        offsetBottom && classes.offsetBottom,
        isAlignCenter && classes.isAlignCenter,
        hasSmallVerticalMargin && classes.hasSmallVerticalMargin,
      )}
    >
      <div
        className={classNames(
          classes.slideLeft,
          hasAutoWidth && classes.autoWidth,
        )}
        style={{flex: flexLeft}}
      >
        <Heading hasNoMaxWidth>
          {hat && <Title isCentered={!!isMobile} hat={hat} />}
          <div className={classes.slideDescriptionContainer}>
            <Title
              variant="h1"
              type={titleType}
              isCentered={!!isMobile}
              hasMarginTop={isMobile}
            >
              {title}
            </Title>
            <Description type={descriptionType}>{description}</Description>
            {ctaLink && ctaTitle && !hasCtaOnRight && (
              <RoundButton color={ctaColor} arrow href={ctaLink}>
                {ctaTitle}
              </RoundButton>
            )}
          </div>
          {isMobile && renderMediaBlock()}
        </Heading>
      </div>
      <div className={classes.slideRight} style={{flex: flexRight}}>
        {hasCtaOnRight && !media && (
          <div className={classes.slideRightCta}>
            <RoundButton color={ctaColor} arrow href={ctaLink}>
              {ctaTitle}
            </RoundButton>
          </div>
        )}
        {!isMobile && renderMediaBlock()}

        {!isMobile && (
          <ImageModal
            open={isModalOpen}
            onClose={handleCloseModal}
            media={allMedias}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        )}
      </div>
    </div>
  )
}

Hero.propTypes = {
  ...heroPropTypes,
  flexLeft: number,
  flexRight: number,
  isWhitePaper: shape({}),
}

Hero.defaultProps = {
  ctaColor: 'primary',
  ctaLink: '',
  ctaTitle: '',
  description: shape({description: ''}),
  descriptionType: 'largeDescription',
  hasCtaOnRight: false,
  hasRoundCorner: false,
  hasSmallVerticalMargin: false,
  hat: '',
  isAlignCenter: false,
  media: null,
  pagesMedia: [],
  offsetBottom: false,
  title: '',
  titleType: 'largeTitle',
  isWhitePaper: false,
  flexLeft: 1,
  flexRight: 1,
}

export default Hero
